.app-container {
  display: flex;
  flex-direction: column;
  min-height: 130vh;
  z-index: 3;
}  

.logo-footer {
  z-index: 1;
}

.gradient-text {
  font-weight: bold;
  background: linear-gradient(to top, #fc3153, #ba0e92, #F24E1E, #f60804);
  /* background: linear-gradient(to top, #f5bb8c, #6f6f6f); */
  -webkit-background-clip: text;
  color: transparent;
}